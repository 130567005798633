<p>satoha App</p>
<section>
    <div class="example-label">Vorratsverwaltung</div>
    <div class="example-button-row">
      <button mat-raised-button routerLink="/list">Liste</button>&nbsp;
      <button mat-raised-button routerLink="/add">Hinzufügen</button>&nbsp;
      <!-- <button mat-raised-button routerLink="/search">Suchen</button> -->
    </div>
    <div *ngIf="status" class="example-form">
      <p>{{status}}</p>
    </div>
</section>