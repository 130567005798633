<button mat-icon-button routerLink="/" color="primary" aria-label="Startseite">
  <mat-icon>home</mat-icon>
</button>

<div class="example-form">
  <app-lebensmittel-suche></app-lebensmittel-suche>

  <app-vorrat-autocomplete-gruppe></app-vorrat-autocomplete-gruppe>
  
  <mat-form-field class="example-full-width">
    <mat-label>Menge</mat-label>
    <input matInput type="number" placeholder="In Gramm" [(ngModel)]="selectedMenge">
  </mat-form-field>
  
  <mat-form-field *ngIf="_global.values$ | async as globals">
    <mat-label>Einheit</mat-label>
    <mat-select [(value)]="selectedEinheit">
      <mat-option *ngFor="let einheit of globals.einheiten;" [value]="einheit.id" >
        {{einheit.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br />

  <mat-form-field *ngIf="_global.values$ | async as globals">
    <mat-label>Kategorie</mat-label>
    <mat-select [(value)]="selectedKategorie">
      <mat-option *ngFor="let kategorie of globals.kategorien" [value]="kategorie.id">
        {{kategorie.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br />

  <mat-form-field *ngIf="_global.values$ | async as globals">
    <mat-label>Lagerort</mat-label>
    <mat-select [(value)]="selectedLagerort">
      <mat-option *ngFor="let ort of globals.lagerorte" [value]="ort.id">
        {{ort.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br />

  <mat-form-field>
    <mat-label>Ablaufdatum</mat-label>
    <input matInput [matDatepicker]="myDatePicker" [min]="minExpDate" [(ngModel)]="selectedAblaufdatum">
    <mat-datepicker-toggle [for]="myDatePicker" matSuffix></mat-datepicker-toggle>
    <mat-datepicker #myDatePicker startView="multi-year"></mat-datepicker>
  </mat-form-field>
</div>


<button mat-button (click)="addItem()">Zu dem Vorrat hinzufügen</button>

<div *ngIf="status" class="example-form">
  <p>{{status}}</p>
</div>

<div *ngIf="lastInsertArticle" class="data">
  <div>Zuletzt hinzugefügt:</div>
  <div>Produkt: {{lastInsertArticle.name}}</div>
  <div>Marker: {{lastInsertArticle.marker}}</div>
</div>
