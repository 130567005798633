import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { MatTableModule } from '@angular/material/table';
import { MatSliderModule } from '@angular/material/slider';
import { LebensmittelSucheComponent } from './lebensmittel-suche/lebensmittel-suche.component';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';

import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { VorratAddComponent } from './vorrat-add/vorrat-add.component';
import { VorratListComponent } from './vorrat-list/vorrat-list.component';

import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

import {MatIconModule} from '@angular/material/icon';
import { VorratAutocompleteGruppeComponent } from './vorrat-autocomplete-gruppe/vorrat-autocomplete-gruppe.component';
import { VorratSearchComponent } from './vorrat-search/vorrat-search.component';

import { GlobalsService } from './common/globals.service';
import { VorratTemplateComponent } from './vorrat-template/vorrat-template.component'; 

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LebensmittelSucheComponent,
    VorratAddComponent,
    VorratListComponent,
    VorratAutocompleteGruppeComponent,
    VorratSearchComponent,
    VorratTemplateComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatSliderModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatSelectModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatIconModule
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de'}, GlobalsService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
